import Vue  from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// Local data storage
import localforage from 'localforage'
const sessionStorage = localforage.createInstance({name: 'escaperoom'})

interface ScenarioSigninForm {
  scenarioName: string
  pin: string
}

interface GmSigninForm {
  user: string
  pass: string
}

interface StoredMFA {
  phone: string
  verified: number
}

const inputDate = function (when?: Date) {
  if (!when) when = new Date();
  const yyyy = when.getFullYear();
  const M = when.getMonth() + 1;
  const d = when.getDate();
  let MM: string, dd: string;
  MM = (M < 10) ? ('0' + M.toString()) : M.toString();
  dd = (d < 10) ? ('0' + d.toString()) : d.toString();
  return '' + yyyy + '-' + MM + '-' + dd;
}

const inputTime = function (when?: Date) {
  if (!when) when = new Date();
  const h = when.getHours();
  const m = when.getMinutes();
  let hh: string, mm: string;
  hh = (h < 10) ? ('0' + h.toString()) : h.toString();
  mm = (m < 10) ? ('0' + m.toString()) : m.toString();
  return '' + hh + ':' + mm;
}

const timeText = function (t: number) {
  if (isNaN(t)) return '00:00';
  const m = Math.floor(t / 60);
  const s = t % 60;
  let mm: string, ss: string;
  mm = (m < 10) ? ('0' + m.toString()) : m.toString();
  ss = (s < 10) ? ('0' + s.toString()) : s.toString();
  return '' + mm + ':' + ss;
}

// Firebase
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously, signInWithEmailAndPassword, EmailAuthProvider, GoogleAuthProvider, signInWithRedirect, getRedirectResult, reauthenticateWithCredential, updatePassword, linkWithPhoneNumber, signInWithPhoneNumber, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc, deleteDoc, collection, query, getDocs, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';
const firebaseApp = initializeApp({
  apiKey: "AIzaSyBeW5VF3DtDdGfGGCT0DCk0y02i6DgGOQk",
  authDomain: "cyber-escape-room.firebaseapp.com",
  projectId: "cyber-escape-room",
  storageBucket: "cyber-escape-room.appspot.com",
  messagingSenderId: "298747396686",
  appId: "1:298747396686:web:e771ff7383086d31f2f91f"
});
const db = getFirestore();
const _functions_ = getFunctions();

import defaulter from '@/modules/defaulter'

const emptySession = {
  id: '',
  scheduledFor: null,
  date: '',
  time: '',
  open: false,
  allowSelfStart: false,
  started: false,
  concluded: false
}

const emptyGmProfile = {
  id: '',
  resetPass: true,
  lastLogin: '',
}

export default new Vuex.Store({
  state: {
    user: null,
    uid:  null,
    signinBusy: false,
    signedInThrough: null,
    confirmationResult: null,
    otpConfigured: false,
    otpCurrent: false,
    mfaSent: false,
    mfaVerified: false,
    scenarioId: 'harmony',
    sessionId:  null,
    scenario: {
      name: 'Harmony',
      description: 'Use your skills to save the Harmony of the Seas from malicious hackers.'
    },
    session: {
      id: '',
      scheduledFor: null,
      date: '',
      time: '',
      open: false,
      allowSelfStart: false,
      started: false,
      concluded: false
    },
    team: {
      id:      null,
      name:    '',
      members: [],
      ready:   0,
      requiresAssistance: 0,
      visitedAquaTheater: 0,
      visitedBoardwalk:   0,
      visitedCentralPark: 0,
      // visitedPromenade:   0,
      // completedEntrance:     0,
      completedIntroduction: 0,
      completedIntroductionVideo: 0,
      completedAquaTheater:  0,
      completedAquaTheaterVideo:  0,
      completedBoardwalk:    0,
      completedBoardwalkVideo:    0,
      completedCentralPark:  0,
      completedCentralParkVideo:  0,
      // completedPromenade:    0,
      // completedPromenadeVideo:    0,
      completedTheater:      0,
      completedBridge:       0,
      lastLocation: '',
      hintsRemaining: 4,
      seenFindLaptopHint: 0,
      timeAquaTheater: 0,
      timeBoardwalk:   0,
      timeCentralPark: 0,
      // timePromenade:   0,
      timeTheater:     0,
      timeBridge:      0,
      timePenalty:     0,
      timeTotal:       0,
    },
    timerLocal: {
      timeAquaTheater: 0,
      timeBoardwalk:   0,
      timeCentralPark: 0,
      // timePromenade:   0,
      timeTheater:     0,
      timeBridge:      0,
      timePenalty:     0,
      timeTotal:       0,
    },
    timerVar: '',
    timerTime: 0,
    timerTimer: null,
    allTeams: {},
    unsubList: {},
    stageAnswers: {
      'FromName':    'FROM',
      'Links':       'SAFE',
      'Attachments': 'CAREFUL',
      'Emotions':    'TIME',
    },
    stageHints: {
      'FromName': [
        'The password is 4 letters long.',
        'The password starts with an "F"'
      ],
      'Links': [
        'The password is 4 letters long.',
        'The password starts with an "S"'
      ],
      'Attachments': [
        'The password is 7 letters long.',
        'The password starts with a "C"'
      ],
      'Emotions': [
        'The password is 4 letters long.',
        'The password starts with a "T"'
      ],
    },
    tacticsQuestions: [
      {
        question: "A message on social media saying \"I see you work for Royal Caribbean Group and was wondering if you have any job openings - here is my resume\"",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Social Engineering"
      },
      {
        question: "Email from Amazon about a delayed package",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Product Impersonation"
      },
      {
        question: "A text from Jason Liberty",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Name Impersonation"
      },
      {
        question: "An email from HR announcing bonuses",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Emotion"
      },
      {
        question: "A threatening call from the IRS stating that you owe money",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Emotion"
      },
      {
        question: "An email from your boss asking you to purchase gift cards on your corporate credit card",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Name Impersonation"
      },
      {
        question: "A DocuSign email stating there is a contract for you to sign",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Product Impersonation"
      },
      {
        question: "An email offering discounted tickets for your favorite sports team",
        options: [
          "Emotion",
          "Name Impersonation",
          "Product Impersonation",
          "Social Engineering"
        ],
        answer: "Social Engineering"
      },
    ],
    dataSensitivityQuestions: [
      {
        question: "A message to HR that contains private information about yourself that you don't want the recipient to be able to send to anyone else",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Restricted > Recipients Only"
      },
      {
        question: "A list of employees participating in a training event that includes their names and email addresses",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Restricted > General"
      },
      {
        question: "Marketing materials for travel agents to send to their clients",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Public"
      },
      {
        question: "A message to your manager asking about deadlines for various projects",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Internal > Sensitive"
      },
      {
        question: "A question for the legal department on the details of a vendor contract",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Restricted > General"
      },
      {
        question: "An invitation to employees for free lunch on Thursday",
        options: [
          "Public",
          "Internal > General",
          "Internal > Sensitive",
          "Restricted > General",
          "Restricted > Recipients Only"
        ],
        answer: "Internal > General"
      },
    ],
    reviewQuestions: [
      {
        question: "One of the first things you should do with an incoming email is to compare from name and from address.",
        options: [
          "True",
          "False"
        ],
        answer: "True"
      },
      {
        question: "You should trust all emails.",
        options: [
          "True",
          "False"
        ],
        answer: "False"
      },
      {
        question: "The company has invested heavily in security, so it's ok to click on links because IT will handle everything.",
        options: [
          "True",
          "False"
        ],
        answer: "False"
      },
      {
        question: "When faced with emotion, we should rush and take action as quick as possible.",
        options: [
          "True",
          "False"
        ],
        answer: "False"
      },
      {
        question: "It's important to take our time when an email comes in that creates emotion.",
        options: [
          "True",
          "False"
        ],
        answer: "True"
      },
      {
        question: "When there are links in an email, we should make sure to hover over them and analyze if they are going somewhere legitimate.",
        options: [
          "True",
          "False"
        ],
        answer: "True"
      },
      {
        question: "We should download attachments even if you aren't expecting them.",
        options: [
          "True",
          "False"
        ],
        answer: "False"
      },
      {
        question: "You should never take a USB found in a parking lot or bathroom and plug it into your computer.",
        options: [
          "True",
          "False"
        ],
        answer: "True"
      },
      {
        question: "If a website is requesting credentials, you should always give them without checking where you're at.",
        options: [
          "True",
          "False"
        ],
        answer: "False"
      },
      {
        question: "If you do click a link, do not provide credentials - always go to the site directly.",
        options: [
          "True",
          "False"
        ],
        answer: "True"
      },
      {
        question: "Two-factor authentication is when a one-time passcode is used to help authenticate to a website or application.  Two-factor authentication could be an SMS or text-based one-time passcode.  Or, it could be an authenticator app (e.g. Google Authenticator, Duo, etc.).  Or, it could be a hardware token.  Regardless, it is a stronger form of authentication.<br><br>Jim knows he uses SMS / text-based authentication to get his one-time passcode to get into Microsoft related applications.  Someone calls Jim and says \"Hi this is Steven with Microsoft.  There's been a lot of fraud out there, so before I continue, I just need to make sure I'm talking to the right person.  Can you give me your one time passcode?\"<br><br>Should Jim give Steve his two-factor authentication (2FA)?",
        info: "Treat all one-time passcodes the same way you would treat your password to your computer and never share it with anyone.  Any exceptions should be approved by IT.",
        options: [
          "Yes",
          "No"
        ],
        answer: "No"
      },
    ],
    // GM Data
    gmProfile: null,
    sessions: null,
  },

  /////////////
  // Getters //
  /////////////
  getters: {
    user: state => state.user,
    uid: state => state.uid,
    signinBusy: state => state.signinBusy,
    userHasPhoneSignin: state => {
      if (!state.user || state.user.isAnonymous) return null
      for (let p=0,l=state.user.providerData.length; p<l; p++) {
        if (state.user.providerData[p].providerId === 'phone') return true
      }
      return false
    },
    userPhoneNumber: state => {
      return (state.user) ? state.user.phoneNumber : ''
    },
    otpConfigured: state => state.otpConfigured,
    otpCurrent: state => state.otpCurrent,
    mfaSent: state => state.mfaSent,
    mfaVerified: state => state.mfaVerified,
    scenarioId: state => state.scenarioId,
    scenario: state => state.scenario,
    sessionsCollectionPath: state => {
      if (state.scenarioId) {
        return 'scenarios/' + state.scenarioId + '/sessions/'
      }
      return null
    },
    sessionPath: state => {
      if (state.scenarioId && state.sessionId) {
        return 'scenarios/' + state.scenarioId + '/sessions/' + state.sessionId
      }
      return null
    },
    sessions: state => state.sessions,
    session: state => state.session,
    sessionIsOpen: state => {
      return (state.session && state.session.open)
    },
    teamsCollectionPath: (state, getters) => {
      if (state.uid && getters.sessionPath) {
        return getters.sessionPath + '/teams/'
      }
      return null
    },
    teamPath: (state, getters) => {
      if (state.uid && getters.sessionPath) {
        return getters.sessionPath + '/teams/' + state.uid
      }
      return null
    },
    team: state => state.team,
    teamName: state => state.team.name,
    seenFindLaptopHint: state => state.team.seenFindLaptopHint,
    stageTimes: state => state.timerLocal,
    timerText (state) {
      return timeText(state.timerLocal.timeTotal)
    },
    stagesComplete: state => {
      let count = 0
      if (state.team.ready) {
        if (state.team.completedAquaTheater) count++
        if (state.team.completedBoardwalk)   count++
        if (state.team.completedCentralPark) count++
        // if (state.team.completedPromenade)   count++
        if (state.team.completedTheater)     count++
      }
      return count
    },
    finalStageComplete: state => {
      return (state.team.completedBridge) ? true : false
    },
    hintsRemaining: state => state.team.hintsRemaining,
    stageAnswers: state => state.stageAnswers,
    stageHints: state => state.stageHints,
    tacticsQuestions: state => state.tacticsQuestions,
    dataSensitivityQuestions: state => state.dataSensitivityQuestions,
    reviewQuestions: state => state.reviewQuestions,
    allTeams: state => state.allTeams,
    teamRankings: state => {
      const ranked = [];
      let team: any, stagesComplete: number, timeTotal: number;
      for (let t in state.allTeams) {
        team = state.allTeams[t];
        stagesComplete = team.completedAquaTheater
          + team.completedBoardwalk
          + team.completedCentralPark
          // + team.completedPromenade
          + team.completedTheater;
        timeTotal = team.timeTotal || 0,
        ranked.push({
          id: t,
          name: team.name,
          stagesComplete: stagesComplete,
          isDone: team.completedTheater?true:false,
          timeTotal: timeTotal,
          timeText: timeText(timeTotal),
          score: 5000 + (stagesComplete*25000) - timeTotal,
          requiresAssistance: team.requiresAssistance
        })
      }
      ranked.sort((a,b) => {
        if (a.score == b.score) return 0;
        return (b.score > a.score) ? 1 : -1;
      })
      return ranked
    },
    // GM Getters
    inputDate: (state) => (when?: Date) => { return inputDate(when)},
    today: () => { return inputDate() },
    gmProfilePath: state => {
      if (state.uid && state.scenarioId) {
        return 'scenarios/' + state.scenarioId + '/gms/' + state.uid
      }
      return null
    },
    gmProfile: state => state.gmProfile,
    userMustResetPass: state => {
      if (state.signedInThrough==='password' && state.gmProfile) return state.gmProfile.resetPass
      return null
    }
  },

  ///////////////
  // Mutations //
  ///////////////
  mutations: {
    sessionPath (state, payload: ScenarioSigninForm) {
      state.scenarioId = payload.scenarioName.toLowerCase()
      state.sessionId  = state.scenarioId + '-' + payload.pin
      // sessionStorage.setItem('sessionId', payload.pin)
    },
    sessionPin (state, payload: string) {
      state.sessionId = state.scenarioId + '-' + payload
    },
    sessionId (state, payload: string) {
      state.sessionId = payload
    },
    sessions (state, payload: object) {
      const sessions = {}
      let scheduledFor: Date
      for (let id in payload) {
        sessions[id] = defaulter.merge(emptySession, payload[id])
        if (payload[id].scheduledFor) {
          scheduledFor = new Date(payload[id].scheduledFor)
          sessions[id].date = inputDate(scheduledFor)
          sessions[id].time = inputTime(scheduledFor)
        }
      }
      state.sessions = sessions
    },
    session (state, payload: object) {
      state.session = defaulter.merge(emptySession, payload)
    },
    team (state, payload: object) {
      state.team = defaulter.merge(state.team, payload)
      state.timerLocal = defaulter.merge(state.timerLocal, {
        timeAquaTheater: state.team.timeAquaTheater,
        timeBoardwalk:   state.team.timeBoardwalk,
        timeCentralPark: state.team.timeCentralPark,
        // timePromenade:   state.team.timePromenade,
        timeTheater:     state.team.timeTheater,
        timeBridge:      state.team.timeBridge,
        timePenalty:     state.team.timePenalty,
        timeTotal:       state.team.timeTotal,
      })
    },
    allTeams (state, payload: object) {
      state.allTeams = payload
    },
    // GM Setters
    gmProfile (state, payload: object) {
      state.gmProfile = defaulter.merge(emptyGmProfile, payload)
    },
  },

  /////////////
  // Actions //
  /////////////
  actions: {

    // Initialize App //

    async initialize ({state, dispatch}) {       
      const auth = getAuth()
      // Keep Store User ID in sync with Firebase Auth
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log('user is now', user)
          state.user = user
          state.uid  = user.uid
          // // Extra checks for actual, non-anonymous users
          // if (!user.isAnonymous) {
          //   const otpStatus = await dispatch('checkOtpStatus')
          //   console.log('otpStatus', otpStatus)
          // }
          // // Check if MFA done recently enough to not be required
          // if (user.phoneNumber) {
          //   let mfa: StoredMFA = await sessionStorage.getItem('mfa')
          //   if (mfa.phone === user.phoneNumber && Date.now() < mfa.verified + 64800000) {
          //     state.mfaSent = true
          //     state.mfaVerified = true
          //   }
          // }
        } else {
          state.user = null
          state.uid  = null
          state.mfaSent = false
          state.mfaVerified = false
        }
        if (user) console.log('uid', user.uid)
        state.signinBusy = false
      })
      getRedirectResult(auth)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access Google APIs.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
    
        // The signed-in user info.
        const user = result.user;
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
    },

    // Firebase Auth //

    async signIn ({state, dispatch}, form: GmSigninForm) {
      console.log('store.signIn')
      state.signinBusy = true
      const auth = getAuth();
      const userCred = await signInWithEmailAndPassword(auth, form.user, form.pass)
      if (userCred) state.signedInThrough = 'password'
    }, // end of signIn (with Email/Password)

    async signInThroughProvider ({state, dispatch}, providerName: string) {
      console.log('store.signInThroughProvider', providerName)
      state.signinBusy = true
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const userCred = await signInWithRedirect(auth, provider)
      if (userCred) state.signedInThrough = providerName
      // signInWithPopup(auth, provider)
      //   .then((result) => {
      //     // This gives you a Google Access Token. You can use it to access the Google API.
      //     const credential = GoogleAuthProvider.credentialFromResult(result);
      //     const token = credential.accessToken;
      //     // The signed-in user info.
      //     const user = result.user;
      //     // ...
      //     console.log('logged in thru Google')
      //     console.log('  credential', credential)
      //     console.log('  token', token)
      //     console.log('  user', user)
      //   }).catch((error) => {
      //     // Handle Errors here.
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     // The email of the user's account used.
      //     const email = error.email;
      //     // The AuthCredential type that was used.
      //     const credential = GoogleAuthProvider.credentialFromError(error);
      //     // ...
      //   });
    }, // end of signInThroughProvider

    async updateCurrentUserPassword ({state}, payload) {
      if (!state.user) return null
      state.signinBusy = true
      // Reauthenticate with current
      if (payload.hasOwnProperty('oldPass') && payload.oldPass) {
        await reauthenticateWithCredential(state.user, EmailAuthProvider.credential(state.user.email, payload.oldPass))
      }
      // Set new
      try {
        await updatePassword(state.user, payload.newPass)
      } catch (error) {
        console.warn('Error updating current user password', error)
        return false
      }
      state.signinBusy = false
      return true
    },

    async linkToNewPhone ({state, getters}, payload) {
      if (!state.user) return false
      const auth = getAuth();
      try {
        state.confirmationResult = await linkWithPhoneNumber(state.user, payload.phone, payload.appVerifier)
        // SMS sent
        console.log('SMS sent to new phone', state.confirmationResult)
        state.mfaSent = true;
        return true
      } catch (error) {
        // Error; SMS not sent
        console.warn('New phone SMS not sent', error)
        return false
      }
    },

    async sendPhoneCode ({state, getters}, payload) {
      const phone = getters.userPhoneNumber
      if (!phone) return false
      const auth = getAuth();
      try {
        state.confirmationResult = await signInWithPhoneNumber(auth, phone, payload.appVerifier)
        // SMS sent
        console.log('SMS sent', state.confirmationResult)
        state.mfaSent = true;
        return true
      } catch (error) {
        // Error; SMS not sent
        console.warn('SMS not sent', error)
        return false
      }
    },

    async confirmPhoneCode ({state, getters}, payload) {
      const auth = getAuth();
      let userCred: any;
      try {
        userCred = await state.confirmationResult.confirm(payload.code)
        console.log('confirmation result', userCred)
        // User signed in successfully.
        if (userCred) {
          state.mfaVerified = true
          // @ts-ignore
          sessionStorage.setItem('mfa', {
            phone: getters.userPhoneNumber,
            verified: Date.now()
          })
          return true
        }
        state.mfaVerified = false
        return false
      } catch (error) {
        // User couldn't sign in (bad verification code?)
        console.log('Failed to confirm phone code', error)
        return false
      }
    },

    async signOut () {
      console.log('store.signOut')
      await signOut(getAuth())
      return true
    },

    // Firebase Firestore //

    async fetchDocument ({commit, dispatch}, payload) {
      let document;
      try {
        document = await getDoc(doc(db, payload.path))
      } catch (error) {
        console.warn('Error fetching document', payload, error)
        return false
      }
      let docData = document.data()
      if (payload.hasOwnProperty('commitTo') && payload.commitTo) {
        commit(payload.commitTo, docData)
        dispatch('subscribeToDocument', payload)
      }
      return docData
    },

    subscribeToDocument ({state, commit}, payload) {
      const path: string = payload.path
      const commitTo: string = payload.commitTo
      if (state.unsubList.hasOwnProperty(commitTo)) {
        if (path === state.unsubList[commitTo].path && commitTo === state.unsubList[commitTo].commitTo) return
        state.unsubList[commitTo].unsub()
      }
      const unsub = onSnapshot(
        doc(db, path),
        (doc) => {
          commit(commitTo, doc.data())
        },
        (error) => {
          console.warn('Could not subscribe to document:', payload, error)
        });
      state.unsubList[commitTo] = (unsub) ? { path, commitTo, unsub } : null
    },

    async fetchCollection ({commit, dispatch}, payload) {
      const path: string = payload.path
      const q = query(collection(db, path));
      const querySnapshot = await getDocs(q);
      const collectionData = {}
      querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        collectionData[doc.id] = doc.data()
      })
      if (payload.hasOwnProperty('commitTo') && payload.commitTo) {
        commit(payload.commitTo, collectionData)
        dispatch('subscribeToDocument', payload)
      }
      return collectionData
    },

    subscribeToCollection ({state, commit}, payload) {
      const path: string = payload.path
      const commitTo: string = payload.commitTo
      if (state.unsubList.hasOwnProperty(commitTo)) {
        if (path === state.unsubList[commitTo].path && commitTo === state.unsubList[commitTo].commitTo) return
        state.unsubList[commitTo].unsub()
      }
      const unsub = onSnapshot(
        collection(db, path),
        (querySnapshot) => {
          const docs = {}
          querySnapshot.forEach(function(doc) {
            docs[doc.id] = doc.data();
          });
          commit(commitTo, docs)
        },
        (error) => {
          console.warn('Could not subscribe to collection:', payload, error)
          commit(commitTo, false)
        });
      state.unsubList[commitTo] = (unsub) ? { path, commitTo, unsub } : null
    },

    // Start or Continue Session //

    async initSession ({state, getters, commit, dispatch}, payload) {
      // Start assuming no team
      commit('team', {id: null})
      // If not already authenticated with firebase, do so now.
      if (!state.uid) {
        const auth = getAuth()
        const cred = await signInAnonymously(auth)
          .catch((error) => {
            console.warn('auth error', error.code, error.message)
            return false
          });
        if (!cred) return false
      }
      // Firestore Paths
      commit('sessionPath', payload)
      const sessionPath = getters.sessionPath
      const teamPath    = getters.teamPath
      // Check specified scenario session exists and is open for new teams.
      const session = await dispatch('fetchDocument', {
        path: sessionPath,
        commitTo: 'session'
      })
      // Check if team already created.
      let team = await dispatch('fetchDocument', {
        path: teamPath,
        commitTo: 'team'
      })
      // Continue if team was already created before, otherwise only if session exists and is open
      if (!(team || (session && session.open))) return false
      // // Create team, if not done already.
      // if (!team) {
      //   await setDoc(doc(db, teamPath), defaulter.merge(state.team, {id: state.uid}), { merge: true })
      //   .catch((error)=>{
      //     console.warn('error creating team', error)
      //   })
      //   team = await dispatch('fetchDocument', {
      //     path: teamPath,
      //     commitTo: 'team'
      //   })
      //   if (!team) {
      //     return false;
      //   }
      // }
      dispatch('subscribeToCollection', {
        path:  sessionPath+'/teams',
        commitTo: 'allTeams'
      })
      return true
    },

    async continueSession ({state, getters, commit, dispatch}) {
      const sessionPin = await sessionStorage.getItem('sessionId')
      if (!(state.uid && state.scenario.name)) return false
      commit('sessionPath', {
        scenarioName: state.scenario.name,
        pin: sessionPin
      })
      await dispatch('fetchDocument', {
        path: getters.sessionPath,
        commitTo: 'session'
      })
      const team = await dispatch('fetchDocument', {
        path: getters.teamPath,
      })
      if (team) {
        // Merge with latest team data model
        await setDoc(doc(db, getters.teamPath), defaulter.merge(state.team, team), { merge: true })
        .catch((error)=>{
          console.warn('error updating team', error)
        })
        // Subscribe to team document
        dispatch('subscribeToDocument', {
          path: getters.teamPath,
          commitTo: 'team'
        })
        // Subscribe to all team documents
        dispatch('subscribeToCollection', {
          path:  getters.teamsCollectionPath,
          commitTo: 'allTeams'
        })
      }
      return true
    },

    // Update Game Progress //

    async updateMyTeam ({state, getters, dispatch}, payload) {
      // Check if team already created.
      const teamPath = getters.teamPath
      if (!state.team.id) {
        await dispatch('fetchDocument', {
          path: teamPath,
          commitTo: 'team'
        })
      }
      // Create team, if not done already.
      if (!state.team.id) {
        console.log('Creating team with ID', state.uid)
        await setDoc(doc(db, teamPath), defaulter.merge(state.team, {id: state.uid}), { merge: true })
        .catch((error)=>{
          console.warn('error creating team', error)
        })
        await dispatch('fetchDocument', {
          path: teamPath,
          commitTo: 'team'
        })
      }
      // Still no team? Fail.
      if (!state.team.id) {
        return false;
      }

      if (!(teamPath)) return null
      payload = defaulter.merge(state.timerLocal, payload)
      await setDoc(doc(db, teamPath), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating team', error)
        return false
      })
      return true
    },

    timerStart ({state, getters, dispatch}, payload: string) {
      if (state.timerTimer) {
        dispatch('timerStop')
      }
      state.timerVar = 'time' + payload;
      console.log('timerStart', state.timerVar, state.timerLocal[state.timerVar], state.timerLocal.timeTotal)
      state.timerTimer = window.setInterval(()=>{
        if (state.timerLocal[state.timerVar] >= 3600) {
          dispatch('timerStop')
          return
        }
        Vue.set(state.timerLocal, state.timerVar, state.timerLocal[state.timerVar]+1)
        let timeTotal = state.timerLocal.timeAquaTheater
          + state.timerLocal.timeBoardwalk
          + state.timerLocal.timeCentralPark
          // + state.timerLocal.timePromenade
          + state.timerLocal.timeTheater
          + state.timerLocal.timeBridge
          + state.timerLocal.timePenalty;
        Vue.set(state.timerLocal, 'timeTotal', timeTotal)
        if (timeTotal%60 === 0) {
          dispatch('updateMyTeam', state.timerLocal)
        }
      }, 1000)
    },
    timerStop ({state, dispatch}) {
      if (state.timerTimer) {
        window.clearInterval(state.timerTimer)
        state.timerTimer = null
      }
      dispatch('updateMyTeam', state.timerLocal)
    },

    async loseHint ({state, dispatch}) {
      if (state.team.hintsRemaining > 0) {
        return await dispatch('updateMyTeam', {hintsRemaining: state.team.hintsRemaining-1})
      }
      state.timerLocal.timePenalty += 0
      dispatch('updateMyTeam', state.timerLocal)
    },
    async refreshHints ({dispatch}) {
      return await dispatch('updateMyTeam', {hintsRemaining: 4})
    },

    async completeStage ({dispatch}, stage) {
      const prop = 'completed' + stage
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async completeStageVideo ({dispatch}, stage) {
      const prop = 'completed' + stage + 'Video'
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async visitStage ({dispatch}, stage) {
      const prop = 'visited' + stage
      const updates = {}
      updates[prop] = 1
      return await dispatch('updateMyTeam', updates)
    },

    async requestAssistance ({dispatch}) {
      return await dispatch('updateMyTeam', {'requiresAssistance': 1})
    },

    ///////////////////////////
    // Game Master Functions //
    ///////////////////////////

    subscribeToGmProfile ({getters, dispatch}) {
      const docPath = getters.gmProfilePath
      if (!docPath) return
      dispatch('subscribeToDocument', {
        path: docPath,
        commitTo: 'gmProfile'
      })
    },

    async updateGmProfile ({getters}, payload) {
      const docPath = getters.gmProfilePath
      if (!docPath) return
      await setDoc(doc(db, docPath), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating document', error)
        return false
      })
      return true
    },

    async listSessions ({getters, dispatch}) {
      return await dispatch('fetchCollection', {path: getters.sessionsCollectionPath})
    },

    subscribeToSessions ({getters, dispatch}) {
      dispatch('subscribeToCollection', {
        path: getters.sessionsCollectionPath,
        commitTo: 'sessions'
      })
    },

    async createSession ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.sessionsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error creating document', error)
        return false
      })
      return true
    },

    async updateSession ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.sessionsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating document', error)
        return false
      })
      return true
    },

    async deleteSession ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload.id)) return null
      await deleteDoc(doc(db, getters.sessionsCollectionPath+payload.id))
      .catch((error)=>{
        console.warn('error deleting document', error)
        return false
      })
      return true
    },

    async subscribeToTeams ({getters, dispatch}) {
      return await dispatch('subscribeToCollection', {
        path: getters.teamsCollectionPath,
        commitTo: 'allTeams'
      })
    },

    async updateTeam ({getters}, payload) {
      if (!(getters.teamsCollectionPath && payload.id)) return null
      await setDoc(doc(db, getters.teamsCollectionPath+payload.id), payload, { merge: true })
      .catch((error)=>{
        console.warn('error updating document', error)
        return false
      })
      return true
    },

    async deleteTeams ({getters}, payload) {
      if (!(getters.sessionsCollectionPath && payload && payload.session && payload.teams)) return null
      let sessionId = payload.session.id
      for (let teamId in payload.teams) {
        deleteDoc(doc(db, getters.sessionsCollectionPath+sessionId+'/teams/'+teamId))
        .catch((error)=>{
          console.warn('error deleting document', error)
          return false
        })
      }
      return true
    },

    // Firebase Functions //

    async checkOtpStatus ({state}) {
      state.signinBusy = true
      const fun = httpsCallable(_functions_, 'otpStatus')
      const res = await fun()
      state.signinBusy = false
      if (res) {
        if (res.data) {
          // @ts-ignore
          state.otpConfigured = res.data.configured
          // @ts-ignore
          state.otpCurrent    = res.data.current
        }
        return res.data
      }
      return false
    },

    async generateOtpSeed () {
      const fun = httpsCallable(_functions_, 'generateOtpSeedForUser')
      const res = await fun()
      return (res && res.data) ? res.data : false
    },

    async checkOtp ({state}, payload) {
      const fun = httpsCallable(_functions_, 'checkOtpForUser')
      const res = await fun(payload)
      // @ts-ignore
      if (res && res.data && res.data.valid) {
        state.otpCurrent = true
        return true
      }
      return false
    },

  } // end of actions
})
