import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// App Pages
const Signin = () => import('@/pages/Signin.vue')
const Entrance = () => import('@/pages/Entrance.vue')
const Lobby = () => import('@/pages/Lobby.vue')
const Introduction = () => import('@/pages/Introduction.vue')
const Maproom = () => import('@/pages/Maproom.vue')
const AquaTheater = () => import('@/pages/AquaTheater.vue')
const Boardwalk = () => import('@/pages/Boardwalk.vue')
const CentralPark = () => import('@/pages/CentralPark.vue')
// const Promenade = () => import('@/pages/Promenade.vue')
const Theater = () => import('@/pages/Theater.vue')
const Bridge = () => import('@/pages/Bridge.vue')
const Conclusion = () => import('@/pages/Conclusion.vue')
const NotFound = () => import('@/pages/NotFound.vue')
// GM Pages
const GameMaster = () => import('@/pages/GameMaster.vue')


Vue.use(VueRouter)

// Route Navigation Guards
const enteredValidSessionPin = function (to, from, next) {
  const team = store.getters.team
  if (team.id || store.getters.sessionIsOpen) {
    next()
  } else {
    next('/')
  }
}
const isSignedIn = function (to, from, next) {
  const team = store.getters.team
  if (team.id) {
    next()
  } else {
    next('/')
  }
}

// Router Paths
export default new VueRouter({
  mode: 'history',
  // base: 'rooms',
  routes: [
    {
      name: 'Signin',
      path: '/',
      component: Signin,
    },
    {
      name: 'Entrance',
      path: '/entrance',
      component: Entrance,
      beforeEnter: isSignedIn
    },
    {
      name: 'Lobby',
      path: '/lobby',
      component: Lobby,
      beforeEnter: enteredValidSessionPin
    },
    {
      name: 'Introduction',
      path: '/introduction',
      component: Introduction,
      beforeEnter: isSignedIn
    },
    {
      name: 'Maproom',
      path: '/maproom',
      component: Maproom,
      beforeEnter: isSignedIn
    },
    {
      name: 'Aqua Theater',
      path: '/aqua-theater',
      component: AquaTheater,
      beforeEnter: isSignedIn
    },
    {
      name: 'Boardwalk',
      path: '/boardwalk',
      component: Boardwalk,
      beforeEnter: isSignedIn
    },
    {
      name: 'Central Park',
      path: '/central-park',
      component: CentralPark,
      beforeEnter: isSignedIn
    },
    // {
    //   name: 'Promenade',
    //   path: '/promenade',
    //   component: Promenade,
    //   beforeEnter: isSignedIn
    // },
    {
      name: 'Theater',
      path: '/theater',
      component: Theater,
      beforeEnter: isSignedIn
    },
    {
      name: 'Bridge',
      path: '/bridge',
      component: Bridge,
      beforeEnter: isSignedIn
    },
    {
      name: 'Conclusion',
      path: '/conclusion',
      component: Conclusion,
      beforeEnter: isSignedIn
    },
    /* Game Master Pages */
    {
      name: 'GameMaster',
      path: '/gm',
      component: GameMaster
    },
    /* Catch All */
    {
      path: '*',
      component: NotFound
    }
  ]
})
